<template>
<div>
  <div>
    <van-nav-bar title="基本情况陈述"  />
  </div>
  <div class="box1">
    <van-nav-bar left-text="个人信息情况陈述" class="title2" />
    <van-row>
      <van-col span="12">
        <van-cell-group>
          <van-field v-model="name" label="姓名" placeholder="请输入姓名"  readonly />
        </van-cell-group>
      </van-col>
      <van-col span="12">
        <van-cell-group>
          <van-field v-model="userSex" label="性别" placeholder="请输入性别" readonly />
        </van-cell-group>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="12">
        <van-cell-group>
          <van-field v-model="cellphone" label="电话号码" placeholder="请输入电话号码" readonly  />
        </van-cell-group>
      </van-col>
      <van-col span="12">
        <van-cell-group>
          <van-field v-model="age" label="年龄" placeholder="请输入年龄" readonly  />
        </van-cell-group>
      </van-col>
    </van-row>
    <van-row>
      <van-cell-group>
        <van-field v-model="sIDCard" label="身份证号" placeholder="请输入身份证号" readonly />
        <van-field v-model="sAddress" label="地址" placeholder="请输入地址"  readonly />
      </van-cell-group>
    </van-row>
  </div>
  <div class="box2">
    <van-nav-bar left-text="纠纷单位信息情况陈述" class="title2" right-text="修改" />
    <van-cell-group>
      <van-field v-model="companyname1" label="单位名称" placeholder="请输入单位名称" />
      <van-field v-model="companyname2" label="单位简称" placeholder="请输入单位简称" />
      <van-field v-model="companyAddress" label="单位地址" placeholder="请输入单位地址" />
      <van-field v-model="companyPerson" label="单位负责人" placeholder="请输入单位负责人" />
      <van-field v-model="companyPersonTel" label="负责人电话" placeholder="请输入负责人电话" />
      <van-field v-model="basicSalary" label="每月工资" placeholder="请输入每月工资" />
      <van-field v-model="ofArea" label="管辖区域" placeholder="请输入管辖区域" />
    </van-cell-group>
  </div>
  <div class="box3">
    <van-nav-bar left-text="调查表情况输出" class="title3" right-text="" />
    <van-row>
      <van-col span="16">
        <van-cell-group>
          <van-field v-model="TotalCode" label="总编码" placeholder="请输入总编码" class="van-field__control2" />
        </van-cell-group>
      </van-col>
      <van-col span="8">
        <van-button type="info" class="info1" @click="showPopup" >查询</van-button>
      </van-col>
    </van-row>
    <van-popup v-model="show" closeable close-icon="clear" >
      <div>
        <div>{{OutputItem}}</div>
        <div>{{OutputItem}}</div>
      </div>
    </van-popup>
  </div>
</div>
</template>
<script>
import { getHistoryList, setHistoryList } from '@/utils/storage'
import { mapGetters, mapState } from 'vuex'
import { getPersonbaseinfo } from '@/api/presentation'
export default {
  name: 'PresenTation',
  data () {
    return {
      search: '', // 输入框的内容
      history: getHistoryList(), // 历史记录
      name: '',
      userSex: '',
      cellphone: '15500007777',
      age: '',
      sIDCard: '',
      sAddress: '',
      companyname1: '',
      companyname2: '',
      companyAddress: '',
      companyPerson: '',
      companyPersonTel: '',
      basicSalary: '',
      ofArea: '',
      TotalCode: '',
      show: false,
      OutputItem: '可以自知道处理决定之日起 30 日内向做出处理决定的单位申请复核，递交复核申请书即可。'
    }
  },
  computed: {
    ...mapState('cart', ['cartList']),
    ...mapGetters('cart', ['cartTotal', 'selCartList', 'selCount', 'selPrice', 'isAllChecked']),
    isLogin () {
      return this.$store.getters.token
    },
    getCellphone () {
      return this.$route.params.cellphone ?? '15500007777'
    }
  },
  created () {
    // 必须是登录过的用户，才能用户购物车列表
    // if (this.isLogin) {
    this.getPersonbaseinfo1()
    // }
  },
  methods: {
    async getPersonbaseinfo1 () {
      const { data } = await getPersonbaseinfo(this.getCellphone)
      this.name = data.name
      this.userSex = data.userSex
      // this.cellphone = data.cellphone
      this.age = data.age
      this.sIDCard = data.sIDCard
      this.sAddress = data.sAddress
      this.companyname1 = data.companyname1
      this.companyname2 = data.companyname2
      this.companyAddress = data.companyAddress
      this.companyPerson = data.companyPerson
      this.companyPersonTel = data.companyPersonTel
      this.basicSalary = data.basicSalary
      this.ofArea = data.ofArea
      // console.log(data)
    },
    showPopup () {
      this.show = true
    },
    goSearch (key) {
      // console.log('进行了搜索，搜索历史要更新', key)
      const index = this.history.indexOf(key)
      if (index !== -1) {
        // 存在相同的项，将原有关键字移除
        // splice(从哪开始, 删除几个, 项1, 项2)
        this.history.splice(index, 1)
      }
      this.history.unshift(key)
      setHistoryList(this.history)

      // 跳转到搜索列表页
      this.$router.push(`/searchlist?search=${key}`)
    },
    clear () {
      this.history = []
      setHistoryList([])
    }
  }
}
</script>
<style lang="less" scoped>
  .title3{
    margin-bottom: 5px;
    width: 100%;
    height: 45px;
    background: linear-gradient( 135deg, #6480DD 0%, #5151BB 100%);
    /deep/ .van-nav-bar__text{
      color: white;
      font-weight: bold;
    }
  }
  .box3{
    margin-top: 20px;
  }
  .info1{
    margin-left: 5px;
    width: 100px;
    height: 44px;
    background: linear-gradient( 90deg, #4C76FE 0%, #3637F2 100%);
    border-radius: 43px 43px 43px 43px;
  }
  .van-cell {
    padding: 2.66667vw 2.26667vw;
    /deep/ .van-field__label {
      width: 5em;
      margin-right: 1vw;
      color: #868181;
    }
    /deep/ .van-field__control {
      padding: 0;
      text-align: right;
      font-weight: bold;
    }
  }
  .title2{
    width: 100%;
    height: 45px;
    background: linear-gradient( 135deg, #6480DD 0%, #5151BB 100%);
    /deep/ .van-nav-bar__text{
      color: white;
      font-weight: bold;
    }
  }
  .box1 {
    width: 100%;
    height: 237px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
    border-radius: 0px 0px 0px 0px;
  }
  .box2 {
    width: 100%;
    height: 296px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
    border-radius: 0px 0px 0px 0px;
  }
</style>
