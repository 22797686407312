<template>
    <div>
      <!-- <h2 class="mb10">Video 播放器基本使用</h2>
      <VideoComponent :src="src" :poster="poster" /> -->
      <h2 class="mt30 mb10">自动播放</h2>
      <VideoComponent autoplay :src="src" :poster="poster"  width="400" height="250"/>
      <h2 class="mt30 mb10">自动截取视频指定帧作为视频封面</h2>
      <VideoComponent :src="src" :second="3" />
    </div>
  </template>
<script>
import VideoComponent from '@/components/VideoComponent'
export default {
  name: 'VideoPlay',
  components: {
    VideoComponent
  },
  data () {
    return {
      src: require('@/assets/files/79095.mp4'), // 相对路径
      // src: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/Bao.mp4',
      poster: require('@/assets/images/79095.jpg')
      // poster: 'https://cdn.jsdelivr.net/gh/themusecatcher/resources@0.0.3/bao.jpg'
    }
  }
}
</script>
