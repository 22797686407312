import request from '@/utils/request'
// 获取当事人基本信息
export const getPersonbaseinfo = (cellphone) => {
  if (cellphone === '') {
    cellphone = '15500007777'
  }
  return request.post('/Interface/service/service.epa?api=getPersonBaseInfo', {
    cellphone
  })
}
